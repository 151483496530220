.sys-guard {
  .sysGuardHeaderTitle {
    margin: 0px;
    font-size: 32px;
    color: #a601f3;
  }

  .MuiButtonBase-root {
    color: white;
    background: #b128f1;
    width: 100px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;

    &:hover {
      background-color: #a601f3;
    }
  }

  .sys-guard-connection-status-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-right: 30px;
  }

  .sys-guard-connection-text {
    color: #a601f3;
    font-weight: 600;
  }

  .sys-guard-status {
    cursor: pointer;
  }
}

.sys-guard.MuiButtonBase-root {
  color: white;
  background: #b128f1;
  margin-right: 10px;
  text-transform: none;
  margin-left: 12px;
  margin-top: 10px;
  width: 140px;

  .MuiButton-label {
    white-space: nowrap;
  }

  &:hover {
    background-color: #a601f3;
  }
}

.sysGuardDrawer {
  background-color: white;
}

.onBoardFormContainer {
  width: 650px;
  padding: 30px 30px;
  box-sizing: border-box;

  .dataSourceTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dataSourceTitle {
    height: 24px;
    width: 300px;
    color: #292929;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1rem;
    white-space: noWrap;
  }
}

$primary-color: #b128f1;
$error-color: #ff4d4d;

.form-group-container {
  padding-top: 10px;
  .MuiButtonBase-root {
    color: white;
    background: #b128f1;
    width: 100px;
    text-transform: capitalize;
    font-size: 15px;

    &:hover {
      background-color: #a601f3;
    }
  }

  .form-group {
    margin-bottom: 16px;

    &.application {
      padding: 10px 0px;
    }

    label {
      font-weight: 500;
      display: block;
      margin-bottom: 4px;
    }

    .required {
      color: $error-color;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiTextField-root {
      width: 100%;
    }

    .error-text {
      color: $error-color;
      font-size: 12px;
      margin-top: 4px;
    }
  }

}

.sysGuardLoader {
  position: absolute;
  top: 46%;
  margin-left: 52.4%;
  z-index: 10;
}